const downloadStore = {
  state: {
    progressList: [],
  },
  mutations: {
    SET_PROGRESS: (state, progressObj) => {
      // 修改进度列表
      if (state.progressList.length) {
        /* console.log(
          state.progressList.find((item) => item.url == progressObj.url)
        ); */
        let hasFile = state.progressList.find(
          (item) => item.url == progressObj.url
        );
        let hasFiles = state.progressList.findIndex(
          (item) => item.url == progressObj.url
        );
        // console.log(hasFile);
        // 如果进度列表存在
        if (hasFile) {
          // 前面说的path时间戳是唯一存在的，所以如果在进度列表中找到当前的进度对象\
          // hasFile = progressObj;
          for (const key in progressObj) {
            hasFile[key] = progressObj[key];
          }
          // state.progressList[hasFiles] = progressObj;
          /* hasFile.path = progressObj.path;
          hasFile.cancel = progressObj.cancel;
          hasFile.progress = progressObj.progress;
          hasFile.status = progressObj.status;
          hasFile.fileName = progressObj.fileName;
          hasFile.url = progressObj.url; */
          /*       state.progressList.find(
            (item) => item.url == progressObj.url
          ).progress = progressObj.progress; // 改变当前进度对象的progress
 */
          /*  state.progressList.find(
            (item) => item.url == progressObj.url
          ).progress = progressObj.progress; // 改变当前进度对象的progress
          state.progressList.find(
            (item) => item.url == progressObj.url
          ).status = progressObj.status; // 改变当前进度对象的status
          state.progressList.find(
            (item) => item.url == progressObj.url
          ).cancel = progressObj.cancel; // 改变当前进度对象的status */
        } else {
          state.progressList.push(progressObj); // 当前进度列表为空，没有下载任务，直接将该进度对象添加到进度数组内
        }
      } else {
        state.progressList.push(progressObj); // 当前进度列表为空，没有下载任务，直接将该进度对象添加到进度数组内
      }
    },
    DEL_PROGRESS: (state, props) => {
      state.progressList.splice(
        state.progressList.findIndex((item) => item.url == props),
        1
      ); // 删除进度列表中的进度对象
    },
    CLEAR_PROGRESS: (state, props) => {
      state.progressList = []; // 清空列表
    },
  },
  actions: {},
  getters: {
    progressList: (state) => state.progressList,
  },
};

export default downloadStore;
