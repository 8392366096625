//把dialog和message组件都导入，install安装并注册组件
import myMenu from "@/components/myMenu.vue";
import popup from "@/components/popup.vue";
import ahButton from "@/components/ahButton.vue";
import Nocontent from "@/components/Nocontent.vue";

const directives = {
  install(Vue) {
    //注意组件命名：建议始终使用 kebab-case 的事件名如下例子：my-event
    Vue.component("myMenu", myMenu);
    Vue.component("popup", popup);
    Vue.component("ah-button", ahButton);
    Vue.component("my-empty", Nocontent);
  },
};
export default directives;
