const routes = [
  {
    path: "/appeal",
    name: "appeal",
    component: () =>
      import(/* webpackChunkName: "appeal" */ "@/views/appeal/appeal.vue"),
    meta: {
      isNav: true,
    },
  },
  {
    path: "/appeal_details",
    name: "appeal_details",
    component: () =>
      import(
        /* webpackChunkName: "appeal" */ "@/views/appeal/appeal_details.vue"
      ),
    meta: {
      isNav: true,
    },
  },
];

export default routes;
