import Vue from "vue";

export default {
  install: function (Vue, opt) {
    Vue.mixin({
      data() {
        return {
          //可设置全局变量
          // reg1: /^[5A-Za-z\_]+$/, // 英文和下划线
          // reg2: /^[5A-Za-z]+$/, // 英文
          // numLetter: /^[5A-Za-z0-9]+$/, // 数字和字母
          // numdot: /[^\d{1,}\.\d{1,}|\d{1,}]/, // 数字和小数点
        };
      },
      filters: {
        formatDate: function (value) {
          return value;
        },
      },
      methods: {
        //全局方法
        getCitys: function () {},

        //全局设置手机号验证 智能输入数字 输入其他字符不显示
        checkPhone: function (rule, value, callback) {
          setTimeout(() => {
            let mobile = /^1[3456789]\d{9}$/; // 手机号
            let num = /^[0-9]+$/; // 数字
            if (value === "") {
              return callback(new Error("手机号不可为空"));
            } else {
              if (!num.test(value)) {
                callback(new Error("请输入有效的手机号码"));
              } else {
                if (!mobile.test(value)) {
                  callback(new Error("请输入有效的手机号码"));
                }
              }
              callback();
            }
          }, 300);
        },

        //全局验证数字不为零
        checkNoZero: function (rule, value, callback) {
          setTimeout(() => {
            if (Number.isInteger(value)) {
              callback(new Error("请输入大于0的数值"));
            } else {
              if (Number(value) <= 0) {
                callback(new Error("请输入大于0的数值"));
              } else {
                callback();
              }
            }
          }, 300);
        },

        //全局验证不能为空
        checkNull: function (rule, value, callback) {
          if (value == "  ") {
            callback(new Error("不能为空"));
          } else {
            callback();
          }
        },
      },
    });
  },
};

// input 限制只输入数字
function onInput(el, ele, binding, vnode) {
  function handle() {
    setTimeout(() => {
      // 只保留数字
      ele.value = ele.value.replace(/[^\d]/g, "");
      //在不是“0.”开头的字符进行修改：“01”=>1
      if (
        ele.value.charAt(0) == "0" &&
        ele.value.charAt(1) != "." &&
        ele.value.length >= 2
      ) {
        ele.value = ele.value.replace(/0/, "");
      }
    }, 300);
  }
  return handle;
}

const numberInput = {
  bind(el, binding, vnode) {
    const ele = el.tagName === "INPUT" ? el : el.querySelector("input");
    ele.addEventListener("input", onInput(el, ele, binding, vnode), false);
  },
};
Vue.directive("number-input", numberInput);

// Vue.prototype.validForbid = function (value) {
// 	value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, "");
// 	return value;
// }

// 格式化水印视频
Vue.prototype.vodeoFormat = function (originUrl) {
  console.log(originUrl);
  if (originUrl) {
    let arr = originUrl.split("/");
    console.log(arr);
    let videoName = arr[arr.length - 1];
    return "http://cloud.nethool.cn/wm-partTime/" + videoName + ".mp4";
  } else {
    return ``;
  }
};

Vue.prototype.validSe = function (value) {
  value = value.replace(/[@#¥$%^&*@#￥%……&*]/g, "").replace(/\s/g, "");
  return value;
};

Vue.prototype.setDotNum = function (value) {
  const regex = /^\D*([0-9]\d*\.?\d{0,2})?.*$/;
  let a = String(value).replace(regex, "$1");
  // console.log(ele.value);
  return a;
};

// input 限制只输入数字
function onInputPrice(el, ele, binding, vnode) {
  function handle() {
    const regex = /^\D*([0-9]\d*\.?\d{0,2})?.*$/;
    ele.value = String(ele.value).replace(regex, "$1");
    console.log(ele.value);
    return ele.value;
    // 只保留数字和小数点
    // ele.value = ele.value.replace(/[^\d]/g, ".");
    ele.value = ele.value
      .replace(/[^\d.]/g, "")
      .replace(/\.{2,}/g, ".")
      .replace(".", "$#$")
      .replace(/\./g, "")
      .replace("$#$", ".")
      .replace(/^\./g, "");
    //防止无输入无限个“.”
    // ele.value = ele.value.replace(/\.+/, ".");
    //在不是“0.”开头的字符进行修改：“01”=>1
    /*     if (
      ele.value.charAt(0) == "0" &&
      ele.value.charAt(1) != "." &&
      ele.value.length >= 2
    ) {
      ele.value = ele.value.replace(/0/, "");
    }
   //获取第一个小数点的索引值
    var index = ele.value.indexOf(".");
    //获取最后一个小数点的索引值
    var lastIndex = ele.value.lastIndexOf(".");
    //判断小数点是不是开头，如果是开头，则去除
    if (index == 0) {
      ele.value = ele.value.replace(/\./, "");
    }
    //只允许小数点后面有2位字符
    if (index >= 1) {
      ele.value = ele.value.substring(0, index + 3);
    }
    //防止小数点后面又出现小数点
    if (index != lastIndex) {
      ele.value = ele.value.substring(0, lastIndex);
    }
    console.log(ele.value); */
  }
  return handle;
}

const numberInputPrice = {
  bind(el, binding, vnode) {
    const ele = el.tagName === "INPUT" ? el : el.querySelector("input");
    ele.addEventListener("input", onInputPrice(el, ele, binding, vnode), false);
  },
};

Vue.directive("price-input", numberInputPrice);

/** 手机号码格式化-隐藏中间四位数字 */
Vue.filter("phoneFilter", function (value) {
  if (!value) return "";
  const phoneFormat =
    value.toString().substring(0, 3) +
    "****" +
    value.toString().substring(7, 11);
  return phoneFormat;
});

//格式化时间
Vue.filter("dateFormat", function (originVal) {
  if (originVal) {
    const dt = new Date(originVal);
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    const ss = (dt.getSeconds() + "").padStart(2, "0");
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
  } else {
    return "-";
  }
});
//格式化金额
Vue.filter("toFixed", function (originVal) {
  if (originVal) {
    return Number(originVal).toFixed(2);
  } else {
    return "0.00";
  }
});

// 格式化时间
Vue.prototype.TimeFormat = function (originVal) {
  if (originVal) {
    // let time =
    //   originVal.indexOf("T") > -1 ? originVal : originVal.split("-").join("/");
    const dt = new Date(originVal);
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    const ss = (dt.getSeconds() + "").padStart(2, "0");
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
  } else {
    return "-";
  }
};

import JSEncrypt from "jsencrypt";
import { forEach } from "jszip";

// 公钥加密
Vue.prototype.setKeyEn = function (publicKey, parameter) {
  //实例化jsEncrypt对象
  let jse = new JSEncrypt();
  //设置公钥
  jse.setPublicKey(publicKey);
  return jse.encrypt(parameter);
};

Vue.prototype.reTxt = function (list, val) {
  if (list.indexOf(val) == "-1") {
    return "---";
  } else {
    return val;
  }
};

Vue.prototype.toFixed = function (originVal) {
  if (originVal) {
    return Number(originVal).toFixed(2);
  } else {
    return "0.00";
  }
};

Vue.prototype.toNo = function (nr, n, str) {
  return Array(n - String(nr), length + 1).join(str || "0") + nr;
};

Vue.directive("dbClick", {
  inserted(el, binding) {
    el.addEventListener("click", (e) => {
      if (!el.disabled) {
        el.disabled = true;
        // el.style.cursor = 'wait'
        setTimeout(() => {
          el.style.cursor = "pointer";
          el.disabled = false;
        }, 2000);
      }
    });
  },
});
Vue.directive("wtClick", {
  inserted(el, binding) {
    el.addEventListener("click", (e) => {
      if (!el.disabled) {
        el.disabled = true;
        // el.style.cursor = 'wait'
        setTimeout(() => {
          el.style.cursor = "pointer";
          el.disabled = false;
        }, 3000);
      }
    });
  },
});

Vue.prototype.reTxt = function (data) {
  for (let s in data) {
    if (data[s] === null || data[s] === undefined || data[s] === "") {
      data[s] = "--";
    }
  }
  return data;
};

// Vue.prototype.cusStatus = function(val) {
//   // 转化状态 （0.未转化 1.己转化 2.未领取）
// 	let str;
// 	if (val == '0') {
// 		str = '未转化'
// 	} else if (val == '1') {
// 		str = '已转化'
// 	} else if (val == '2') {
// 		str = '未领取'
// 	}else  {
// 		str = '-'
// 	}
// 	return str
// }
Vue.prototype.cusStatus = function (val) {
  // 领取状态 （2.未领取 1.已领取）
  let str;
  if (val == "2") {
    str = "未领取";
  } else if (val == "1") {
    str = "已领取";
  } else {
    str = "-";
  }
  return str;
};
Vue.prototype.cusDataSource = function (val) {
  // 领取状态 （0.未领取 1.已领取）
  let str;
  if (val == "1") {
    str = "客户小程序";
  } else if (val == "2") {
    str = "报价器小程序";
  } else {
    str = "-";
  }
  return str;
};
Vue.prototype.cusSource = function (val) {
  // 来源：1.微信 2.抖音 3.快手 4.小红书 5.分享 6.公共
  let str;
  if (val == "1") {
    str = "微信";
  } else if (val == "2") {
    str = "抖音";
  } else if (val == "3") {
    str = "快手";
  } else if (val == "4") {
    str = "小红书";
  } else if (val == "5") {
    str = "分享";
  } else if (val == "6") {
    str = "公共";
  } else {
    str = "-";
  }
  return str;
};

Vue.prototype.EmojiExchangeString = function (str) {
  var patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
  str = str.replace(patt, function (char) {
    var H, L, code;
    if (char.length === 2) {
      H = char.charCodeAt(0); // 取出高位
      L = char.charCodeAt(1); // 取出低位
      code = (H - 0xd800) * 0x400 + 0x10000 + L - 0xdc00; // 转换算法
      return "&#" + code + ";";
    } else {
      return char;
    }
  });
  return str;
};
Vue.prototype.StringExchangeEmoji = function (str) {
  var reg = /\&#.*?;/g;
  var result = str.replace(reg, function (char) {
    var H, L, code;
    if (char.length == 9) {
      code = parseInt(char.match(/[0-9]+/g));
      H = Math.floor((code - 0x10000) / 0x400) + 0xd800;
      L = ((code - 0x10000) % 0x400) + 0xdc00;
      return unescape("%u" + H.toString(16) + "%u" + L.toString(16));
    } else {
      return char;
    }
  });
  return result;
};

Vue.prototype.hdsTos = function (str) {
  let arr = str.split(":");
  let a = arr[0] * 60 * 60;
  let b = arr[1] * 60;
  let c = arr[2];
  return a + b + c;
};

Vue.prototype.timeTosTring = function (str) {
  let HH = Number(str.split("时").shift());
  let mm = Number(str.split("时").pop().split("分").shift());
  let ss = Number(str.split("时").pop().split("分").pop().split("秒").shift());
  let a = "",
    b = "",
    c = "";
  console.log(HH, mm, ss);
  if (HH) {
    a = HH + "小时";
  }
  if (mm) {
    b = mm + "分钟";
  }
  if (ss) {
    c = ss + "秒";
  }
  return a + b + c;
};

// 根据文件名后缀区分 文件类型
/*
 * @param: fileName - 文件名称
 * @param: 数据返回 1) 无后缀匹配 - false
 * @param: 数据返回 2) 匹配图片 - image
 * @param: 数据返回 3) 匹配 txt - txt
 * @param: 数据返回 4) 匹配 excel - excel
 * @param: 数据返回 5) 匹配 word - word
 * @param: 数据返回 6) 匹配 pdf - pdf
 * @param: 数据返回 7) 匹配 ppt - ppt
 * @param: 数据返回 8) 匹配 视频 - video
 * @param: 数据返回 9) 匹配 音频 - radio
 * @param: 数据返回 10) 其他匹配项 - other
 */
Vue.prototype.matchType = function (fileName) {
  // 后缀获取
  var suffix = "";
  // 获取类型结果
  var result = "";
  try {
    var flieArr = fileName.split(".");
    suffix = flieArr[flieArr.length - 1].toLowerCase();
  } catch (err) {
    suffix = "";
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }
  // 图片格式
  var imglist = [
    "png",
    "jpg",
    "jpeg",
    "bmp",
    "gif",
    "PNG",
    "JPG",
    "JPEG",
    "BMP",
    "GIF",
  ];
  // 进行图片匹配
  result = imglist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "image";
    return result;
  }
  // 匹配txt
  var txtlist = ["txt"];
  result = txtlist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "txt";
    return result;
  }
  // 匹配 excel
  var excelist = ["xls", "xlsx"];
  result = excelist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "excel";
    return result;
  }
  // 匹配 word
  var wordlist = ["doc", "docx"];
  result = wordlist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "word";
    return result;
  }
  // 匹配 pdf
  var pdflist = ["pdf"];
  result = pdflist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "pdf";
    return result;
  }
  // 匹配 ppt
  var pptlist = ["ppt"];
  result = pptlist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "ppt";
    return result;
  }
  // 匹配 视频
  var videolist = ["mp4", "m2v", "mkv", "MP4", "M2V", "MKV"];
  result = videolist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "video";
    return result;
  }
  // 匹配 音频
  var radiolist = ["mp3", "wav", "wmv"];
  result = radiolist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "radio";
    return result;
  }
  // 匹配 压缩包
  var radiolist = [
    "rar",
    "zip",
    "arj",
    "gz",
    "tar",
    "7z",
    "RAR",
    "ZIP",
    "ARJ",
    "GZ",
    "TAR",
    "7Z",
  ];
  result = radiolist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "zip";
    return result;
  }
  // 其他 文件类型
  result = "other";
  return result;
};

// 格式化成品时长
Vue.filter("theFinishedTime", function (originVal) {
  if (originVal && originVal.length > 2) {
    if (originVal.includes("时")) {
      let hh = Number(originVal.split("时")[0]);
      let mm = Number(originVal.split("时").pop().split("分")[0]);
      let ss = Number(
        originVal.split("时").pop().split("分").pop().split("秒")[0]
      );
      let hhStr = hh ? hh + "小时" : "";
      let mmStr = mm ? mm + "分钟" : "";
      let ssStr = ss ? ss + "秒" : "";
      return `${hhStr}${mmStr}${ssStr}`;
    } else {
      return originVal;
    }
  } else {
    return "-";
  }
});

// 格式化空数据
Vue.filter("reSet", function (originVal) {
  if (!originVal || originVal === null || originVal === "") {
    return "-";
  } else {
    return originVal;
  }
});

//格式化时间

Vue.prototype.toDay = function (originVal) {
  if (originVal) {
    const dt = new Date(originVal);
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    return `${y}-${m}-${d}`;
  } else {
    return "-";
  }
};

//检验特殊字符
Vue.prototype.isSpec = function (value) {
  // true : 不包含空格和特殊字符；false：包含空格或特殊字符
  if (value) {
    // 规则对象(flag)
    let flag = true;
    // let specialKey =  "[`~!#$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
    // 空格、~、!、@、#、$、%、^、&、*、(、（、)、）、-、+、=、[、]、{、}、|、\、;、:、'、"、/、<、>、?、
    let specialKey = `~!@#$%^&*+=[]{}|\;:'"/<>?`;
    // 判断 value 是否包含特殊字符
    for (let i = 0; i < value.length; i++) {
      if (specialKey.indexOf(value.substr(i, 1)) != -1) {
        flag = false;
        return flag;
      }
    }
    return flag;
  } else {
    return false;
  }
  // 使用 例：this.isSpec('123.mp4');
};

// 格式化视频大小
Vue.filter("formatSize", function (Val) {
  let KB = Val / 1024;
  let MB = KB / 1024;
  // 不够一M的显示K，保留小数1位;  M保留一位小数，K显示整数
  if (!Val || Val === null || Val === "") {
    return "-";
  }
  if (MB < 1) {
    return Math.round(KB) + "K";
  } else {
    return MB.toFixed(1) + "M";
  }
});

Vue.directive("ellipsis", {
  bind: function (el) {
    let frontLen = 8,
      endLen = "",
      str = el.innerText;
    endLen = str.split(".").pop().length + 2;
    var len = str.length - frontLen - endLen;
    var xing = "";
    if (len < 3) {
      for (var i = 0; i < len; i++) {
        xing += ".";
      }
    } else {
      xing = "...";
    }
    let html =
      str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
    if (str.length - endLen > frontLen) {
      el.innerText = html;
    } else {
      el.innerText = str;
    }
  },
});

//检验特殊字符
Vue.prototype.ssToTime = function (timeVal) {
  console.log(timeVal);
  if (timeVal <= 0) {
    // this.Jjrwxq()
    return "00时00分00秒";
  }
  let result = parseInt(timeVal);
  let y =
    Math.floor(result / 86400) < 10
      ? "0" + Math.floor(result / 86400)
      : Math.floor(result / 86400);

  let h =
    Math.floor((result / 3600) % 24) < 10
      ? "0" + Math.floor((result / 3600) % 24)
      : Math.floor((result / 3600) % 24);

  let m =
    Math.floor((result / 60) % 60) < 10
      ? "0" + Math.floor((result / 60) % 60)
      : Math.floor((result / 60) % 60);

  let s =
    Math.floor(result % 60) < 10
      ? "0" + Math.floor(result % 60)
      : Math.floor(result % 60);

  let res = "";

  let hh = `${(h + "").padStart(2, "0")}时`;

  let mm = `${(m + "").padStart(2, "0")}分`;

  let ss = `${(s + "").padStart(2, "0")}秒`;

  return hh + mm + ss;
};

let btnlength1 = 6, // 长按钮最低长度
  btnlength2 = 3; // 中长按钮最低长度
//confirm按钮长度
Vue.prototype.btnCN = function (val) {
  if (!val) return;
  let len = val.length;
  if (len >= btnlength1) {
    return "btn--min" + btnlength1;
  } else if (len > btnlength2) {
    return "btn--min" + btnlength2;
  } else {
    return "btn--min1";
  }
};

//ahButton按钮长度
Vue.prototype.btnAhCN = function (len) {
  if (!len) return;
  if (len >= btnlength1) {
    return "min" + btnlength1;
  } else if (len > btnlength2) {
    return "min" + btnlength2;
  } else {
    return "";
  }
};
